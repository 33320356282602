import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import client from "./client.js";
import BlockContent from "@sanity/block-content-to-react";
import '../css/recipe.css';

export default function OnePost() {
  const [postData, setPostData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    client.fetch(
        `*[slug.current == $slug]{
          title,
          slug,
          source,
          notes,
          mainImage{
            asset->{
              _id,
              url
             }
           },
          ingredientList,
          steps
       }`,
        { slug }
      )
      .then((data) => setPostData(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!postData) return <div>Loading...</div>;

  function toggleIngredientList() {
    var list = document.getElementById("ingredientList");

    if (list.style.maxHeight != "2000px") {
        list.style.maxHeight = "2000px";
    }
    else {
        list.style.maxHeight = "60px";
    }
  };

  return (
    <div>
      <header>
        <h1>{postData.title}</h1>
        <div class="source">
            <a href={postData.source} target="_blank">This recipe is inspired by (or even copied directly from) this one</a>
        </div>
        <div class="notes">
            <p>{postData.notes}</p>
        </div>
      </header>
      <main>
        <div class="ingredients" id="ingredientList">
          <button class="btn" onClick={toggleIngredientList}>Ingredients</button>
          <ul>
            {postData.ingredientList &&
              postData.ingredientList.map((ingredient, index) => (
                <li key={index}>{ingredient}</li>
            ))}
          </ul>
        </div>
        <div class="instructions">
          <h2>Instructions</h2>
            <BlockContent
              blocks={postData.steps}
              projectId={client.clientConfig.projectId}
              dataset={client.clientConfig.dataset}
            />
        </div>
      </main>
      <footer>
        <Link to={"/"}>Back to Recipe List</Link>
        <a href="https://www.drewstillwell.com">drewstillwell.com</a>
      </footer>

    </div>

        
  );
}

