import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import client from "./client.js";
import '../css/main.css';

export default function AllRecipes() {
  
  const [allPostsData, setAllPosts] = useState(null);
  const query = '*[_type == "recipe"] | order(title) {title, slug}';
  
  useEffect(() => {
    client.fetch(query)
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  return (
    <div>
      <div class="name_banner">
        <a href="https://www.stillcookingwell.com">still[cooking]well</a>
      </div>
      <h1>Recipes</h1>
      <div>
        <ul>
          {allPostsData &&
              allPostsData.map((post, index) => (
                <li><Link to={"/" + post.slug.current} key={post.slug.current}>
                  <span key={index}>
                    <span>{post.title}</span>
                  </span>
                </Link></li>
              ))}
        </ul>
      </div>
      <div class="bottom_banner">
        <a href="https://www.drewstillwell.com">drew stillwell</a>
      </div>
    </div>
  );
}

